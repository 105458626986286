import { SurveyResponseDto } from "../types/survey";
import httpService from "./httpService";

/**
 * Submit a aurvey response
 * 
 * @returns survey response
 */
export function createOrUpdateSurvey(locationId: number, paymentToken: string, surveyResponseDto: SurveyResponseDto): Promise<SurveyResponseDto> {
    return httpService
      .post(`/api/locations/${locationId}/surveys/${paymentToken}`, surveyResponseDto)
      .then((response): SurveyResponseDto => response.data);
}

/**
 * Get a survey response
 * 
 * @returns survey response
 */
export function getSurvey(locationId: number, paymentToken: string): Promise<SurveyResponseDto> {
  return httpService
    .get(`/api/locations/${locationId}/surveys/${paymentToken}`)
    .then((response): SurveyResponseDto => response.data);
}

