import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  text?: string | number;
  setText?: (text: string) => void;
  valid?: boolean;
  rows: number;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export default function TextAreaFieldInput({
  text,
  setText,
  className,
  value,
  onChange,
  valid,
  rows,
  ...props
}: TextInputProps): JSX.Element {
  return (
    <textarea
      {...props}
      className={twMerge(
        'rounded-sb-md bg-sb-light-blue border-0 placeholder:text-opacity-35 placeholder:text-black px-4 text-[17px] leading-[21px] w-full pt-4',
        classNames({
          'bg-sb-purple': valid,
          'bg-sb-light-pink border border-sb-pink': valid === false,
        }),
        className,
      )}
      value={value ?? text ?? ''}
      rows={rows}
      onChange={onChange ?? ((e): void => setText?.(e.target.value))}
    />
  );
}
