export enum Language {
  NL = 'nl',
  EN = 'en',
}

export interface DefaultURLQuery {
  lId?: number;
  tId?: number;
  shopId?: number;
  payment?: string;
  paymentId?: number;
  dateParam?: string;
  token?: string;
}

export interface TicketShopOverviewDto {
    /**
     * The name of the ticket shop.
     */
    name: string;
    /**
     * The booking fee of the ticket shop.
     */
    bookingFee: number;
    /**
     * Time zone of the ticket shop.
     */
    timeZone: string;
    /**
     * The name of the location
     */
    locationName: string;
    /**
     * Lowest price for each day in the period specified.
     */
    dayLowestPrices: DayLowestPrice[];
}

export interface DayLowestPrice {
  /**
   * The date of the lowest price.
   */
  date: Date;

  /**
   * The lowest price.
   */
  lowestPrice: number;
}

export type Price = {
  price: number;
  discountedPrice?: number;
};

export enum EventType {
  SUBMIT_USER_DETAILS = 'SUBMIT_USER_DETAILS',
  SUBMIT_PAYMENT = 'SUBMIT_PAYMENT',
}

/**
 * The payment status of a payment.
 * Categories: paid, failed, pending.
 */
export enum ProgressStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  PENDING = 'pending',
}
