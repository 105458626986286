import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserDetailsPage from '../pages/UserDetailsPage';
import PaymentPage from '../pages/PaymentPage';
import DefaultLayout from '../components/layouts/DefaultLayout';
import OrderSuccessPage from '../pages/OrderSuccessPage';
import ExtendedNavigate from './ExtendedNavigate';
import OrderLandingPage from '../pages/OrderPage';
import ProcessPostPaymentPage from '../pages/ProcessPostPaymentPage';
import ShopLayout from '../components/layouts/ShopLayout';
import {
  DATE_PICKING_ROUTE,
  ORDER_FAILURE_ROUTE,
  ORDER_PENDING_ROUTE,
  ORDER_PROCEEDING_ROUTE,
  ORDER_ROUTE,
  ORDER_SUCCESS_ROUTE,
  PAYMENT_ROUTE,
  USER_DETAILS_ROUTE,
} from '../constants';
import DatePickingPage from '../pages/DatePickingPage';
import OrderFailurePage from '../pages/OrderFailurePage';
import OrderPendingPage from '../pages/OrderPendingPage';
import NotActivePage from '../pages/NotActivePage';
import SurveyPage from '../pages/survey/SurveyPage';
import NotFoundPage from '../pages/NotFoundPage';
import SurveyCompletedPage from '../pages/survey/SurveyCompletedPage';

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<DefaultLayout />}>
        <Route path="" element={<ShopLayout />}>
          <Route index element={<ExtendedNavigate to={DATE_PICKING_ROUTE} />} />
          <Route path={DATE_PICKING_ROUTE} element={<DatePickingPage />} />
          <Route path={ORDER_ROUTE} element={<OrderLandingPage />} />
          <Route path={USER_DETAILS_ROUTE} element={<UserDetailsPage />} />
          <Route path={PAYMENT_ROUTE} element={<PaymentPage />} />
        </Route>
        <Route path={ORDER_PROCEEDING_ROUTE} element={<ProcessPostPaymentPage />} />
        <Route path={ORDER_SUCCESS_ROUTE} element={<OrderSuccessPage />} />
        <Route path={ORDER_FAILURE_ROUTE} element={<OrderFailurePage />} />
        <Route path={ORDER_PENDING_ROUTE} element={<OrderPendingPage />} />
        <Route path="/not-active" element={<NotActivePage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/survey-completed" element={<SurveyCompletedPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/*" element={<Navigate to={`${DATE_PICKING_ROUTE}?tId=1&lId=1`} replace />} />
      </Route>
    </Routes>
  );
}
