import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PiSmiley, PiSmileyMeh, PiSmileySad, PiSmileyWink } from 'react-icons/pi';
import ContentPanel from '../../components/layouts/ContentPanel';
import { DefaultURLQuery } from '../../types/misc';
import useQueryParams from '../../hooks/useQuery';
import { createOrUpdateSurvey, getSurvey } from '../../services/surveyService';
import Button from '../../components/buttons/Button';
import SurveyButton from '../../components/buttons/SurveyButton';
import LabeledTextAreaInput from '../../components/inputs/LabeledTextAreaInput';

const smileyOptions = [
  { value: 1, text: 'ontevreden', color: 'red', icon: <PiSmileySad size={48} className='transition-none duration-0' />},
  { value: 2, text: 'matig', color: 'orange', icon: <PiSmileyMeh size={48} className='transition-none duration-0' /> },
  { value: 3, text: 'voldoende', color: 'lightgreen', icon: <PiSmiley size={48} className='transition-none duration-0' /> },
  { value: 4, text: 'uitstekend', color: 'green', icon: <PiSmileyWink size={48} className='transition-none duration-0' /> }
];

const validColors = ["red", "orange", "lightgreen", "green"] as const;
const isValidColor = (color: string): color is typeof validColors[number] => validColors.includes(color as any);
const npsOptions = Array.from({ length: 10 }, (_, i) : number => i + 1);
const getNpsColor = (value: number) : 'red' | 'orange' | 'green' => {
    if (value >= 1 && value <= 6) return "red";
    if (value === 7 || value === 8) return "orange";
    return "green";
  };

type SurveyFormState = {
    entrance: number | null;
    horeca: number | null;
    ambience: number | null;
    nps: number | null;
    comment: string;
};

export default function SurveyPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.survey' });
  const { lId, token } = useQueryParams<DefaultURLQuery>();
  const [usedHoreca, setUsedHoreca] = useState<boolean>(false);

  const [surveyForm, setSurveyForm] = useState<SurveyFormState>({
    entrance: null,
    horeca: null,
    ambience: null,
    nps: null,
    comment: '',
  });

  if (!lId || !token) {
    navigate('/not-found');
    return null;
  }

  useEffect((): void => {
    getSurvey(lId, token).then((survey): void => {
      setSurveyForm(survey);
      setUsedHoreca(survey.horeca !== null);
    }).catch((error): void => {
      console.log(error);
    });
  }, []);

  const handleSelect = (name: string, value: number): void => {
    setSurveyForm((prev): SurveyFormState => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleHorecaSelection = (value: boolean): void => {
    setUsedHoreca(value);
    setSurveyForm((prev): SurveyFormState => ({
      ...prev,
      horeca: value ? prev.horeca : null
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();

    if (!lId || !token) {
        toast.error('location or token is not found');
        return;
    }
    
    createOrUpdateSurvey(lId, token, {
        entrance: surveyForm.entrance!,
        horeca: surveyForm.horeca!,
        ambience: surveyForm.ambience!,
        nps: surveyForm.nps!,
        comment: surveyForm.comment
    }).then(() : void => {
      navigate(`/survey-completed?token=${token}&lId=${lId}`);
    }).catch((error) : void => {
        console.log(error);
    });
  };

  return (
    <ContentPanel className='lg:mt-[112px]'>
      <h1 className="font-['ginto-bold'] text-[24px]">{t('title')}</h1>
      <p>{t('description')}</p>
      <form onSubmit={handleSubmit}>
        {['entrance', 'ambience'].map((field) : JSX.Element => (
          <div key={field} className='mb-4'>
            <p>{t(`label.${field}`)}</p>
            <div className="flex md:gap-4">
              {smileyOptions.map(({ value, icon, text, color }) : JSX.Element => (
                <SurveyButton
                  key={value}
                  variant={surveyForm[field as keyof SurveyFormState] === value ? (isValidColor(color) ? color : 'white') : "white"}
                  onClick={() : void => handleSelect(field, value)}
                  className="gap-2.5 p-4 rounded-full lg:ml-8"
                >
                  <span className='duration-0'>{icon}</span> <span className='hidden lg:block'>{text}</span>
                </SurveyButton>
              ))}
            </div>
          </div>
        ))}
        <div className='mb-4'>
          <p className='mb-2'>{t('label.used_horeca')}</p>
          <div className="flex gap-4">
            <SurveyButton
              variant={usedHoreca === false ? "red" : "white" }
              onClick={() : void => handleHorecaSelection(false)}
              className="p-2 rounded-full h-10 w-20"
            >
              {t('label.no')}
            </SurveyButton>
            <SurveyButton
              variant={usedHoreca === true ? "green" : "white" }
              onClick={() : void => handleHorecaSelection(true)}
              className="p-2 rounded-full h-10 w-20"
            >
              {t('label.yes')}
            </SurveyButton>
            </div>
        </div>
        {usedHoreca && ['horeca'].map((field) : JSX.Element => (
          <div key={field} className='mb-4'>
            <p>{t(`label.${field}`)}</p>
            <div className="flex md:gap-4">
              {smileyOptions.map(({ value, icon, text, color }) : JSX.Element => (
                <SurveyButton
                  key={value}
                  variant={surveyForm[field as keyof SurveyFormState] === value ? (isValidColor(color) ? color : 'white') : "white"}
                  onClick={() : void => handleSelect(field, value)}
                  className="gap-2.5 p-4 rounded-full lg:ml-8"
                >
                  {icon} <span className='hidden lg:block'>{text}</span>
                </SurveyButton>
              ))}
            </div>
          </div>
        ))}
        <div className='mb-4'>
          <p>{t(`label.nps`)}</p>
          <div className="flex gap-2 flex-wrap">
            {npsOptions.map((value) : JSX.Element => (
              <SurveyButton
                key={value}
                variant={Number(surveyForm.nps) === value ? isValidColor(getNpsColor(value)) ? getNpsColor(value) : "green"  : "white"}
                onClick={() : void => handleSelect("nps", value)}
                className="gap-1 p-4 w-12 h-12 flex items-center justify-center rounded-full"
              >
                {value}
              </SurveyButton>
            ))}
          </div>
        </div>
        <LabeledTextAreaInput name='comment' label={t("label.comment")} rows={5} value={surveyForm.comment} onChange={(e) : void => setSurveyForm({ ...surveyForm, comment: e.target.value })} />
        <Button type="submit" className='mt-4'>{t("send_button")}</Button>
      </form>
    </ContentPanel>
  );
}
