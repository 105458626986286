import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = {
  variant?: 'red' | 'orange' | 'lightgreen' | 'green' | 'white';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variants = {
  red: 'bg-[#E74C3C] text-white',
  orange: 'bg-[#E67E22] text-white',
  lightgreen: 'bg-[#2ECC71] text-white',
  green: 'bg-[#27AE60] text-white',
  white: 'bg-white text-black',
};

export default function SurveyButton({
  className,
  variant = 'white',
  ...props
}: ButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        'disabled:opacity-30 flex whitespace-nowrap flex-row disabled:hover:bg-opacity-100 items-center justify-center font-ginto-bold bg-black text-white text-[17px] leading-[17px] rounded-sb-lg p-5 h-[60px]',
        variants[variant],
        className,
      )}
    />
  );
}
