import React, { ComponentProps } from 'react';
import Labeled from '../misc/Labeled';
import TextAreaFieldInput from './TextAreaFieldInput';

type LabeledTextAreaProps = {
  label?: string;
  mandatory?: boolean;
  errorMessage?: string;
  rows?: number;
} & ComponentProps<typeof TextAreaFieldInput>;

export default function LabeledTextAreaInput({
  label = '',
  mandatory,
  placeholder,
  autoComplete,
  name,
  errorMessage,
  rows,
  ...props
}: LabeledTextAreaProps): JSX.Element {
  return (
    <Labeled label={`${label}:${mandatory ? '*' : ''}`} className="flex flex-col gap-1">
      <TextAreaFieldInput
        {...props}
        placeholder={placeholder ?? label}
        autoComplete={autoComplete}
        name={name ?? autoComplete}
        rows={rows ?? 3}
      />
      <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5">{errorMessage}</label>
    </Labeled>
  );
}
